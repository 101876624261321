import React from "react";
import {Card} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import { Link } from "gatsby";

import carelessImg from '../../images/services/Careless Driving.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";
import Routes from "../../constant/routes";

const Items = [
  {key: 1, title: 'How much does a careless driving ticket cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, you are pleading guilty to the violation and the fine in Missouri is between $50-$100, plus court costs between $25-$75 – PLUS the hundreds, even thousands, of dollars more you will have to pay for insurance each year for the next 3-5 years. <strong>TicketTamer can fix your careless driving ticket $175 in most cases --and you won’t have to go to court. 97percent success rate.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you are pleading guilty to the violation. In Missouri, the fine range is for careless driving is between $50 and $100. But it could be more depending upon a lot of factors, including the violation, your driving record, and the court. 
    <br/><br/>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information.<ArrowRightOutlined /></a> */}
    {/* <br/><br/> */}
    You’ll also have to pay court costs, which range from $25 to $75.
    <br/><br/>
    You will also have to pay hundreds, even thousands more for your insurance – for the next 3-5 years -- because insurers will use the ticket to increase your rates. 
    {/* <br/><br/> */}
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    This is the most expensive way to handle your ticket. 
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up – and you won’t have to go to court in most cases</strong>
    <br/><br/>
    
    </div>
    
  </div>},
  {key: 2, title: 'What you absolutely need to know before you pay your careless driving ticket.', preview: <div>
    <div className="BodyGreyLeft mb16">There are five things you need to know before you just pay your ticket.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There are five things you need to know before you just pay your ticket.
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You’ll be pleading guilty to the violation, and it will be on your record forever.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You’ll get 4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars each year for the next 3-5 years. This is why just paying the ticket isthe most expensive way to handle your ticket.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If you get too many points, your license will be suspended or even revoked. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    The points will drop off your record after 3 years, but the violation will stay on forever and insurance companies can use this to raise your rates or even deny you coverage.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down – and you won’t have to go to court in most cases.
    </Card>
    </div>
  </div>},
  {key: 3, title: 'How many points will I have on my license if I plead guilty and pay my court fine for a careless driving ticket without hiring a careless driving ticket lawyer?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, you will get 4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. Click here for Missouri’s point system.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you will get 4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. 
    {/* <br/><br/> */}
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a> */}
    <br/><br/>
    This is why just paying the ticket is the most expensive way to handle your ticket. 
    <br/><br/>
    And by the way, those points will also put your license at risk of suspension or even revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months. 
    {/* <br/><br/> */}
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation.<ArrowRightOutlined /></a> */}
    <br/><br/>
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down  – and you won’t have to go to court in most cases.
    </div>
  </div>},
  {key: 4, title: 'Why should I hire a careless driving ticket lawyer to fix my ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Just paying your careless driving ticket is the worst thing you can do. It will put 4 points on your record for 3 years and result in an insurance increase of hundreds, if not thousands of dollars a year -- for 3-5 years, depending upon the state and your insurer! Click here for more information on tickets and insurance.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Just paying your careless driving ticket is the worst thing you can do. It will put 4 points on your record for 3 years and result in an insurance increase of hundreds, if not thousands of dollars a year -- for 3-5 years, depending upon the state and your insurer! 
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    {/* <br/><br/> */}
    It will also put your license at risk of suspension or even revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months. 
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a> */}
    {/* <br/><br/> */}
    You can avoid all of this by hiring a lawyer to fix your ticket – and the lawyers at TicketTamer are the best. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation down  – and you won’t have to go to court in most cases.
    
    </div>
  </div>},
  {key: 5, title: 'How much does a careless driving ticket lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">The cost of a careless driving ticket lawyer in Missouri will vary depending upon a lot of factors,including the speed limit, how fast you were going, your driving record, and the court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The cost of a careless driving ticket lawyer in Missouri will vary depending upon a lot of factors,including the speed limit, how fast you were going, your driving record, and the court.
    <br/><br/>
    TicketTamer charges $175 for basic careless driving tickets: the $75 base fee we charge for all tickets, plus a $100 surcharge because it is the more serious charge of careless driving  – and you won’t have to go to court in most cases.
    <br/><br/>
    Click here for the five questions you should ask before hiring a lawyer to fix your careless driving ticket.
    
    </div>
  </div>},
  {key: 6, title: 'How much will my insurance rates increase if I plead guilty and pay my court fine for a careless driving ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Hundreds, even thousands, of dollars, each year for the next 3-5 years.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Hundreds, even thousands, of dollars, each year for the next 3-5 years.
    <br/><br/>
    How much will depend on your situation, including where you live, how many points you already have, and whether you have younger drivers on your policy. 
    {/* <br/><br/> */}
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on insurance and tickets<ArrowRightOutlined /></a> */}
    <br/><br/>
    <strong>TicketTamer can help you avoid this by negotiating with the prosecutor to reduce your ticket down to a non-moving or no-point violation. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation  – and you won’t have to go to court in most cases.</strong>
    </div>
  </div>},
  {key: 7, title: 'How do I dismiss a careless driving ticket in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">You can go to court and ask the judge to dismiss it. He almost certainly won’t because careless driving tickets are serious tickets.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    You can go to court and ask the judge to dismiss it. He almost certainly won’t because careless driving tickets are serious tickets.
    <br/><br/>
    The best way is to hire TicketTamer to fix it for you, without your having to go to court. While the ticket won’t be “dismissed,” we will negotiate with the prosecutor to reduce it to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up–  and you won’t have to go to court in most cases.
    </div>
  </div>},
  {key: 8, title: 'How many points does a careless driving ticket give you in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Careless driving tickets are 4 points in Missouri, and stay on your record for three years. Insurance companies use these points to increase your insurance rates by hundreds of dollars a year, sometimes thousands, for 3-5 years.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Careless driving tickets are 4 points in Missouri, and stay on your record for three years. Insurance companies use these points to increase your insurance rates by hundreds of dollars a year, sometimes thousands, for 3-5 years. 
    {/* <br/><br/> */}
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a> */}
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down by negotiating with the prosecutor to reduce your ticket to a non-moving or no-point violation  – and you won’t have to go to court in most cases.</strong>
  </div>
  </div>},
  {key: 9, title: 'How long does a careless driving ticket stay on your record in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Your careless driving ticket will stay on your record forever if you don’t fix it. The 4 points that you will get in point states like Missouri will be on your record for 3 years
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your careless driving ticket will stay on your record forever if you don’t fix it. The 4 points that you will get in point states like Missouri will be on your record for 3 years. 
    {/* <br/><br/> */}
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a> */}
    <br/><br/>
    Even when the points fall off of your record, the careless driving violation will stay on your record forever. Because the violation is still on your record, insurance companies will charge you hundreds, even thousands of dollars more each year for 3-5 years, depending upon the insurer and the state.
    {/* <br/><br/> */}
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    <strong>TicketTamer can keep your record clean and insurance rates down by negotiating with the prosecutor to reduce your ticket to a non-moving or no-point violation– and you won’t have to go to court in most cases.</strong>
    </div>
  </div>},
];

const CarelessDrivingLawyers = (props) => {
  return <ServiceTemplateCascade 
  title="Careless Driving Lawyers" 
  items={Items}
  image={carelessImg}
  metaDescription="Submit your careless driving ticket - Get your quote instantly - Schedule your free consultation"
  />;
}

export default CarelessDrivingLawyers;
